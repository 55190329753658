.question-section {
  background: transparent;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  width: 92vw;
}

.question-align {
  align-items: center;
  display: flex;
  min-height: 10px;
  text-align: left;
}

.question-icon {
  background: none;
  border: none;
  color: #1b6aae;
  cursor: pointer;
  font-size: 22px;
  margin-left: auto;
  margin-right: 8px;
}

.rotate {
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

.answer-divider {
  border-top: 1px solid lightgray;
}

.answer {
  margin-left: 8px;
  margin-right: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
}
